import Icon from '../Icon'

interface Props {
  stars?: number
  totalRatings?: number | null
  variant?: 'blue' | 'black'
}

function StarsReview({ stars = 0, totalRatings, variant = 'blue' }: Props) {
  const fullStar = (
    <Icon
      name={variant === 'blue' ? 'FullStar' : 'FullStarBlack'}
      width={16}
      height={16}
    />
  )

  const emptyStar = (
    <Icon
      name={variant === 'blue' ? 'EmptyStar' : 'EmptyStarBlack'}
      width={16}
      height={15}
    />
  )

  const handleTotalRatings = (ratings: number | null | undefined) => {
    return ratings === 0 || ratings === null || ratings === undefined
      ? 'hidden'
      : ''
  }

  return (
    <div className="flex items-center">
      {stars > 0 && (
        <>
          {stars >= 1 ? fullStar : emptyStar}
          {stars >= 2 ? fullStar : emptyStar}
          {stars >= 3 ? fullStar : emptyStar}
          {stars >= 4 ? fullStar : emptyStar}
          {stars >= 5 ? fullStar : emptyStar}
        </>
      )}
      <span
        className={`ml-2 text-xs font-roboto  ${handleTotalRatings(
          totalRatings
        )} ${variant === 'blue' ? 'text-lightGray01' : 'text-primaryDark'}`}
      >
        ({totalRatings})
      </span>
    </div>
  )
}

export default StarsReview
