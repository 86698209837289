import { useCallback } from 'react'

import type { LinxMetaNotFound } from '../types'
import { useLinxEventDispatch } from './useLinxEventDispatch'

export const useLinxNotFoundView = () => {
  const { dispatchLinxEvent } = useLinxEventDispatch()

  const sendNotFoundViewEvent = useCallback(() => {
    const linxMetaNotFound: LinxMetaNotFound = {
      page: 'not_found',
    }

    return dispatchLinxEvent(linxMetaNotFound)
  }, [dispatchLinxEvent])

  return { sendNotFoundViewEvent }
}
